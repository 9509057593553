.privacy-policy-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #000;
    padding: 20px;
    margin: 0 auto;
    max-width: 70%;
    background-color: #fff;
}

.privacy-title {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.privacy-policy-container h2 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 20px;
}

.privacy-policy-container p {
    margin: 10px 0;
    font-size: 1rem;
}

.privacy-policy-container ul {
    margin: 10px 0 20px 20px;
    padding-left: 20px;
}

.privacy-policy-container ul li {
    list-style-type: disc;
    margin-bottom: 5px;
    font-size: 1rem;
}

.footer-text {
    font-size: 0.9rem;
    text-align: right;
    margin-top: 30px;
    color: #555;
}