body {
  margin: 0;
  font-family: 'RoobertoR';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'GTMI';
  src: url('./assets/font/GT-Super-Display-Medium-Italic.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'GTM';
  src: url('./assets/font/GT-Super-Display-Medium.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'RoobertoB';
  src: url('./assets/font/Roobert-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RoobertoL';
  src: url('./assets/font/Roobert-Light.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'RoobertoR';
  src: url('./assets/font/Roobert-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}