:root {
    --vertical-spacing: 3vh;
    --side-spacing: 15%
}

.carousel {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.carousel-header {
    position: absolute;
    margin-bottom: auto;
    top: 30%;
    text-align: center;
    z-index: 10;
    color: #fff;
}

.carousel-header h1 {
    font-family: 'GTMI';
    font-size: 3rem;
    margin-bottom: 0px;
}

.carousel-header p {
    margin-top: 0px;
    margin-bottom: var(--vertical-spacing);
    font-size: 1.25rem;

}

.carousel-breakLine-container {
    position: absolute;
    display: flex;
    justify-content: center;
    margin-bottom: auto;
    z-index: 10;
    top: 52%;
    width: 100%;
}

.carousel-breakLine {
    width: 30%;
    height: 2px;
    border-radius: 20px;

    opacity: 0.7;
    background-color: #ccc;
}

.carousel-slide-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.carousel-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-slide.active {
    opacity: 1;
    z-index: 2;
}

.carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -5;
    background-color: #02231a;
}

.carousel-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    margin-top: 5vw;
    top: 50%;
    width: 100%;
    z-index: 2;
    color: #fff;
    opacity: 1;
    /* We let GSAP control initial fade. */
}

.carousel-text h2 {
    margin-top: 0px;
    font-family: 'RoobertoL';
    font-size: 3vw;
    margin-bottom: 0px;
}

.carousel-text p {
    text-align: center;
    font-size: 1.2rem;
    width: 40%;
}

.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 5vw;
    height: 5vw;
    z-index: 10;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #ccc;
}

.left-arrow {
    left: min(var(--side-spacing), 20%);
}

.right-arrow {
    right: min(var(--side-spacing), 20%);
}

.carousel-pagination {
    position: absolute;
    bottom: 5%;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 20;
}

.carousel-dot {
    width: 10px;
    height: 10px;
    background-color: rgb(204, 204, 204, 0.4);
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.6s ease;
}

.carousel-dot.active {
    background-color: #fff;
}

@media (max-width: 768px) {

    _::-webkit-full-page-media,
    _:future,
    :root {
        .carousel-text h2 {
            margin-top: 10px;
        }
    }

    .carousel {
        height: 80vh;
        flex-direction: column;
        justify-content: flex-start;
    }

    .carousel-header {
        top: 20%;
        padding: 0 10%;
    }

    .carousel-header h1 {
        font-size: 2rem;
    }

    .carousel-header p {
        font-size: 1rem;
    }

    .carousel-breakLine-container {
        position: relative;
        padding-top: 0%;
        top: 39%;
    }

    .carousel-slide {
        justify-content: flex-start;
    }

    .carousel-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .carousel-text {
        top: 41%;
    }


    .carousel-breakLine {
        width: 30%;
    }

    .carousel-slide-container {
        height: calc(100vh - 150px);
    }

    .carousel-text h2 {
        font-size: 2rem;
    }

    .carousel-text p {
        font-size: 1rem;
        width: 80%;
    }

    .carousel-arrow {
        display: none;
    }
}