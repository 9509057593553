.header-header-container {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    background-color: transparent;
    height: 12%;
    z-index: 1000;
    width: 100%;
    padding: 2rem;
}

.wordmark-header {
    flex: 1;
    display: flex;
    align-items: center;
}

.wordmark-header img {
    width: 200px;
    height: auto;
    max-width: 100%;
    margin-right: auto;
}

.header-navLinks-container {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}

.menu-icon {
    color: #02231a;
    width: 2.5rem;
    height: 2rem;
    z-index: 100;
}

.nav {
    display: flex;
    align-items: center;
}

.close-menu {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
}

.close-icon {
    color: white;
    width: 2rem;
    height: 2rem;
}

.nav-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 2000;
}

.nav-list li {
    margin-left: 2.8rem;
}

.header-nav-link {
    height: 30px;
    z-index: 1000;
    color: #02231a;
    background-color: transparent;
    border-radius: none;
    border: none;
    font-family: 'RoobertoR';

    font-size: 1rem;
    transition: opacity 0.3s;
    pointer-events: auto !important;
}

.header-header-container.scrolled .header-nav-link {
    color: #fff;
    transition: color 0.5s ease-in-out;
    /* Adjust link color when scrolled with smooth transition */
}

.header-header-container.scrolled .wordmark-header img {
    filter: invert(99%) sepia(94%) saturate(28%) hue-rotate(52deg) brightness(106%) contrast(100%);
    transition: filter 0.5s ease-in-out;
    /* Add smooth transition to the filter effect */
}

.menu-icon.scrolled rect {
    color: white;
    transition: filter 0.5s ease-in-out;
}

.header-nav-link:hover {
    font-family: 'RoobertoB';
}

.header-nav-link-disabled {
    opacity: 0.6;
    pointer-events: none;
}

@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
    .nav-list {
        display: -webkit-flex;
    }


}

@media (max-width: 768px) {

    .header-header-container {
        width: 100%;
        padding-top: 0rem;
        padding-bottom: 1rem;
    }

    .header-navLinks-container {
        display: none;
    }

    .wordmark-header {
        text-align: center;
        justify-content: center;
        flex: auto;
    }

    .wordmark-header img {
        width: 15%;
        margin-right: 0;
        filter: invert(99%) sepia(94%) saturate(28%) hue-rotate(52deg) brightness(106%) contrast(100%);
    }

    .header-header-container.scrolled .wordmark-header img {
        filter: none;
    }

    .menu-toggle {
        position: relative;
        display: block;
    }

    .nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #02231a;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: none;
    }

    .header-nav-link {
        color: white;
        letter-spacing: 1px;
        font-size: 2.5rem;
    }


    .nav.open {
        display: flex;
    }

    .close-menu {
        display: block;
    }

    .nav-list {
        text-align: center;
        flex-direction: column;
    }

    .nav-list li {
        margin: 1rem 0;
    }
}