.footer-section {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    z-index: -10;
    color: white;
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4vh;
}

.footer-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    padding-top: 50px;
    padding-left: 2.5%;
    padding-right: 2.5%;
    margin-bottom: -25px;
    padding-bottom: 0px;
    color: #75b2b2;
    z-index: 10;
}

.pdf-container a {
    padding-left: 20px;
    color: #75b2b2;
}


.footer-item {
    font-size: 1rem;
    text-decoration: none;
    color: #75b2b2;
}

.footer-disclosure {
    width: 90%;
    color: #75b2b2;
    padding: 10px;
    font-size: 0.9rem;
    line-height: 1.5;
    text-align: left;
}

.footer-disclosure a {
    color: #75b2b2;
}

@media (max-width: 768px) {
    .footer-row {
        flex-direction: column-reverse;
        font-size: 10px;
        text-align: center;
    }

    .pdf-container {
        display: flex;
        gap: 20px;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .pdf-container a {
        padding-left: 0px;
    }

    .footer-section {
        height: 500px;
    }

    .footer-disclosure {
        font-size: 10px;
        text-align: center;
    }
}